export const state1 = {
    ball0: false,
    ball1: true,
    ball2: true,
    ball3: false,
};
export const state2 = {
    ball0: false,
    ball1: false,
    ball2: true,
    ball3: true,
};

export const state3 = {
    ball0: true,
    ball1: false,
    ball2: false,
    ball3: true,
};

export const state4 = {
    ball0: true,
    ball1: true,
    ball2: false,
    ball3: false,
};
